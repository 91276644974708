import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import { useAppConfig } from "src/store/app-config";
import buildPath from "src/utils/build-path";
import { clearSession } from "src/utils/jwt";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";
import Login from "../pages/auth/Login";
import { TIME_OUT } from "src/config";
import { useIdleTimer } from "react-idle-timer";

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      clearSession();
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export const AdminGuard = ({ children }) => {
  const { isAdmin, isSubAdmin, logout } = useAuth();

  const IDLE_TIMEOUT = TIME_OUT;
  const impersonate = localStorage.getItem("isImpersonate");

  const handleLogout = () => {
    if (impersonate === "true") {
      // Do nothing if impersonate is true
    } else {
      logout();
    }
  };
  const { reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleLogout,
    debounce: 250, // Optional: debounce idle events
  });

  useEffect(() => {
    const handleActivity = () => {
      reset();
    };
    const handleVideoActivity = () => {
      reset();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("touchstart", handleActivity);
    document.querySelectorAll("video").forEach((video) => {
      video.addEventListener("play", handleVideoActivity);
      video.addEventListener("playing", handleVideoActivity);
    });
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.addEventListener("touchstart", handleActivity);
      document.querySelectorAll("video").forEach((video) => {
        video.removeEventListener("play", handleVideoActivity);
        video.removeEventListener("playing", handleVideoActivity);
      });
    };
  }, [reset]);

  if (isAdmin || isSubAdmin) {
    return <>{children}</>;
  }
  return <Navigate to="/user" />;
};

export const SubAdminGuard = ({ children }) => {
  const { isSubAdmin } = useAuth();

  if (isSubAdmin) {
    return <Navigate to={PATH_DASHBOARD.dashboard.root} />;
  }

  return <>{children}</>;
};

export const UserGuard = ({ children }) => {
  const { isAdmin, isSubAdmin, logout } = useAuth();
  const IDLE_TIMEOUT = TIME_OUT;
  const impersonate = localStorage.getItem("isImpersonate");
  const location = useLocation();
  const targetPath = "/user/subscriptionEng/";
  const targetPath2 = "/user/subscriptionDoc/";
  const targetPath3 = "/user/help-center/videos";
  const handleLogout = () => {
    if (
      impersonate === "true" ||
      location.pathname.startsWith(targetPath) ||
      location.pathname.startsWith(targetPath2) ||
      location.pathname.startsWith(targetPath3)
    ) {
      // Do nothing if impersonate is true
    } else {
      logout();
    }
  };
  const { reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleLogout,
    debounce: 250, // Optional: debounce idle events
  });

  useEffect(() => {
    const handleActivity = () => {
      reset();
    };
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("touchstart", handleActivity);
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.addEventListener("touchstart", handleActivity);
    };
  }, [reset]);
  const packageStatus = JSON.parse(localStorage.getItem("package_status"));
  const { pathname } = useLocation();
  if (
    packageStatus &&
    pathname !== PATH_USER.onlineStore.productSubscription.packages.root &&
    pathname !== PATH_USER.onlineStore.productSubscription.checkout &&
    pathname !==
      buildPath(PATH_USER.onlineStore.productSubscription.checkout, "payment")
  ) {
    return (
      <Navigate to={PATH_USER.onlineStore.productSubscription.packages.root} />
    );
  }

  if (isAdmin || isSubAdmin) {
    return <Navigate to="/admin" />;
  }

  return <>{children}</>;
};

export const HideLeads = ({ children }) => {
  const { config } = useAppConfig();

  if (Object.keys(config).length > 0) {
    const leadsEnable = Boolean(config?.leads_enable?.status);

    if (leadsEnable) return <>{children}</>;

    return <Navigate to="/404" replace />;
  }

  return <LoadingScreen />;
};
