import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import sbLogo from "../images/sbLogo.png";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import useAuth from "src/hooks/useAuth";
import "../css/bootstrap.min.css";
import "../js/menu.js";

const Index = () => {
  const { t } = useTranslation();
  const { user, logout, isAdmin } = useAuth();
  const isLoggedIn = !user;
  function toggleMenu() {
    let nav = document.getElementById("navigation");
    nav.classList.toggle("navigation--visible");
  }

  return (
    <div>
      {" "}
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="title" content="Sambusiness" />
        <title>Sambusiness</title>
        <link
          rel="icon"
          href={localStorage.getItem("favicon") || "public/favicon/favicon.ico"}
          type="image/x-icon"
        />
        <script src="js/jquery-1.12.4.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>
        <meta
          name="keywords"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <meta name="author" content="Sambusiness" />
        <meta
          name="description"
          content="If crypto succeeds, it's not because it empowers better people. It's because it empowers better institutions."
        />
        <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <body
        style={{
          fontFamily: "'Montserrat', sans-serif",
          backgroundColor: "#0a1d3b",
        }}
      >
        <div class="bodyBox">
          <div class="container" id="navbar">
            <div class="container">
              <header class="page-header">
                <div class="page-header__top">
                  <div class="page-header__logo">
                    <a href="/">
                      <img src={sbLogo} />
                    </a>
                  </div>
                  <div class="page-header__toggle" onClick={toggleMenu}>
                    ☰
                  </div>
                </div>
                <nav class="page-header__bottom">
                  <ul id="navigation" class="navigation">
                    <li class="navigation__item">
                      <a href="/">{t("global.home")}</a>
                    </li>
                    {/* <li class="navigation__item">
                      <a href="#">About</a>
                    </li>
                    <li class="navigation__item">
                      <a href="#">Services</a>
                    </li> */}
                    <li class="navigation__item">
                      <a href="/faq">FAQ</a>
                    </li>
                    <LanguagePopover />

                    <li class="navigation__item">
                      {isLoggedIn ? (
                        <>
                          {" "}
                          <a
                            class="loginButton"
                            href={
                              isAdmin
                                ? "/admin/dashboard/business"
                                : "/user/dashboard"
                            }
                          >
                            {t("global.logIn")}
                          </a>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a class="loginButton" href="/auth/login">
                            {t("global.Dashboard")}
                          </a>
                        </>
                      )}
                    </li>
                  </ul>
                </nav>
              </header>
            </div>
          </div>
        </div>
        <div>
          <section class="faqSection">
            <div className="container">
              <Typography color={"white"} variant="h6" marginBottom={3}>
                {t("global.PrivacyTerms")}
              </Typography>

              <Typography color={"white"}>
                {t("global.investors")}
                <br />
                <br />
                {t("global.comprehensive")}
                <br />
                <br />

                {t("global.refundable")}
                <br />
                <br />
                {t("global.emphasize")}
                <br />
                <br />
                <List>
                  <ListItem>{t("global.guarantee1")}</ListItem>
                  <ListItem>{t("global.guarantee2")}</ListItem>
                  <ListItem>{t("global.guarantee3")}</ListItem>
                  <ListItem>{t("global.guarantee4")}</ListItem>
                </List>
                <br />
                <br />
                {t("global.clarifySambusiness")}
                <br />
                <br />
                {t("global.clarify")}
                <br />
                <br />
                {t("global.clarify2")}
                <br />
                <br />
                {t("global.clarify3")}
                <br />
                <br />
                {t("global.clarify4")}
                <br />
                <br />
                {t("global.clarify5")}
                <br />
                <br />
                {t("global.clarify6")}
                <br />
                <br />
                {t("global.clarify7")}
              </Typography>
            </div>
          </section>
        </div>
      </body>
    </div>
  );
};

export default Index;
