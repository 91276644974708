import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const VideoTraining = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const CryptoCurrencies = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const AutomaticTrading = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const Staking = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const Stocks = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const FractionalRealEstate = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const NetworkMarketing = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const SubscriptionVdo = [
  {
    path: "subscription",
    children: [
      { index: true, element: <Navigate to="video-training" /> },
      {
        path: "video-training",
        element: (
          <VideoTraining
            title="Formation en crypto-monnaies"
            category="1"
            lang="fr"
          />
        ),
      },
      {
        path: "crypto-currencies",
        element: (
          <CryptoCurrencies
            title="Signaux de crypto-monnaie"
            category="2"
            lang="fr"
          />
        ),
      },
      {
        path: "automatic-trading",
        element: (
          <AutomaticTrading
            title="Formation en Trading Automatique"
            category="3"
            lang="fr"
          />
        ),
      },
      {
        path: "staking",
        element: (
          <Staking
            title="Formation en Staking et Lending"
            category="4"
            lang="fr"
          />
        ),
      },
      {
        path: "stocks",
        element: (
          <Stocks
            title="Formation sur les Actions en Bourse"
            category="5"
            lang="fr"
          />
        ),
      },
      {
        path: "fractional-real-estate",
        element: (
          <FractionalRealEstate
            title="Formation en Immobilier Fractionné"
            category="6"
            lang="fr"
          />
        ),
      },
      {
        path: "network-marketing",
        element: (
          <NetworkMarketing title="Formation en  MLM" category="7" lang="fr" />
        ),
      },
    ],
  },
];

export default SubscriptionVdo;
